import Vue from 'vue'
import path from "@/cLibrary/utils/path";
import Welcome from '../views/Welcome.vue';

const modules = [];
const cIds = {};

if (typeof require === 'undefined') {
	/* webpack-strip-block:removed */
} else {
	const cFiles = require.context('../cLibrary/project/', true, /.vue$/); // vue组件
	const cJson = require.context('../cLibrary/project/', true, /.json$/); // vue组件的配置
	
	cJson.keys().forEach(item => {
		const context = cJson(item).default || cJson(item)
		if (context.id && context.status) {
			cIds[context.id] = context
		}
	});
	cFiles.keys().forEach(item => {
		const context = cFiles(item).default || cFiles(item)
		const name = path.basename(item, '.vue')
		if (cIds[name]) {
			modules.push({
				config: cIds[name],
				name,
				context
			})
		}
	});
}

modules.forEach(el => Vue.component(el.name, el.context))

Vue.prototype.$components = modules.map(el => {
	return {
		labels: el.config.labels,
		id: el.name,
		type: el.context.name
	}
})

export default Welcome;
