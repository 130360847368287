
// 这是标准组件的目录集合
const basic = {
	TY: {
		name: '通用',
		next: {
			"01": {
				name: '功能键',
				next: {
					"01": "按钮",
					"02": "页签",
					"03": "下拉框",
					"04": "开关",
					"05": "输入框",
					"06": "分页栏",
					"07": "时间选择器",
				}
			},
			"02": {
				name: "图例",
				next: {
					"01": "单级图例",
					"02": "二级图例",
					"03": "热力图例",
				}
			},
			"03": {
				name: "版块标题",
				next: {
					"01": "一级标题",
					"02": "二级标题",
					"03": "三级标题",
				}
			},
			"04": {
				name: "指标图",
				next: {
					"01": "上下",
					"02": "左右旧版",
					"03": "左右新版",
					"04": "待定",
					"05": "待定",
					"06": "待定",
				}
			},
			"05": {
				name: "聚合卡片",
				next: {
					"01": "视频卡片",
					"02": "图片卡片",
					"03": "待定",
					"04": "待定",
					"05": "待定",
					"06": "待定",
				}
			}
		}
	},
	TB: {
		name: "图表",
		next: {
			"02": {
				name: "柱状图",
				next: {
					"01": "基础图",
					"02": "堆叠图",
					"03": "折柱混合图",
				}
			},
			"03": {
				name: "条形图",
				next: {
					"01": "基础图",
					"02": "格子图",
				}
			},
			"04": {
				name: "环饼图",
				next: {
					"01": "基础图",
					"02": "多组环图",
					"03": "玫瑰图",
					"04": "扇形玫瑰图",
					"05": "半环图",
				}
			},
			"05": {
				name: "折线图",
				next: {
					"01": "基础图",
					"02": "面积图",
				}
			},
			"06": {
				name: "雷达图",
				next: {
					"01": "待定",
				}
			},
			"07": {
				name: "关系树图",
				next: {
					"01": "气泡图",
				}
			},
			"08": {
				name: "散点图",
				next: {
					"01": "待定",
				}
			},
			"09": {
				name: "仪表盘",
				next: {
					"01": "待定",
				}
			},
			10: {
				name: "表格",
				next: {
					"01": "待定",
				}
			},
			11: {
				name: "词云",
				next: {
					"01": "基础图",
				}
			},
		}
	},
	YW: {
		name: "业务",
		next: {
			"01": {
				name: '体征看板',
				next: {
					"01": "一级体征",
					"02": "二级体征",
					"03": "三级体征",
					"04": "体征容器",
				}
			},
			"02": {
				name: "视频墙",
				next: {
					"01": "视频单元",
					"02": "视频矩阵",
				}
			}
		}
	},
	MB: {
		name: "母版",
		next: {
			"01": {
				name: "聚合页",
				next: {
					"01": "待定"
				}
			},
			"02": {
				name: "菜单页",
				next: {
					"01": "顶部菜单页",
					"02": "左侧菜单页",
					"03": "底部菜单页",
					"04": "混合菜单页",
				}
			},
			"03": {
				name: "单页",
				next: {
					"01": "待定"
				}
			},
			"04": {
				name: "功能页",
				next: {
					"01": "轨迹页",
					"02": "列表页",
					"03": "加载页",
				}
			},
			"05": {
				name: "弹窗页",
				next: {
					"01": "待定",
				}
			},
		}
	}
}
const types = {}
const reduce = (obj, parent) => {
	Object.keys(obj).sort().forEach(key => {
		const item = obj[key]
		if (item.next) {
			item.open = true
			item.key = key
			if (parent) {
				item.parent = parent
				item.pkey = parent.key + '' + item.key
			}
			reduce(item.next, item)
		} else {
			const pkey = parent.pkey + '' + key
			const arr = []
			if (parent) {
				if (parent.parent) {
					arr.push(parent.parent.name)
				}
				arr.push(parent.name)
			}
			arr.push(item)
			types[pkey] = arr
		}
	})
}
reduce(basic)
export default {
	name: 'Welcome',
	components: {
	},
	data () {
		return {
			types,
			basic,
			routes: [],
			list: [],
			active: '',
			labels: {
				全部: 0,
				其他: 0,
			},
			selectLabel: '全部',
			isCE: process.env.VUE_APP_PROJECT_NAME === 'dsj_ce_web',
		}
	},
	created () {
		// this.getData()
		// window.vw = this
	},
	mounted () {
		if (this.isCE) {
			document.querySelector('.back-nav').style.display = 'none';
			this.initComponents()
			this.onScroll()
		} else {
			this.initRouters()
		}
	},
	filters: {
		getType (type) {
			return (types[type] ? types[type].join('-') : '')
		},
		getSmallType (type) {
			return (types[type] ? types[type][types[type].length - 1] : type)
		},
	},
	methods: {
		solveEvent (type, value) {
			switch (type) {
				case 'changeBtnIndex':
					this.info.btnIndex = value
					break
			}
		},
		initRouters () {
			const allRoutes = []
			getRoutes(this.$router.options.routes, '/')
			this.routes = allRoutes
			function getRoutes (routes, path) {
				let routePath = path
				for (let i = 0; i < routes.length; i++) {
					const route = routes[i]
					if (route.meta && route.meta.cLibrary) {
						routePath = path + '/' + route.path
						routePath = routePath.replace(/\/+/g, "/")
						if (!route.children) {
							allRoutes.push({
								path: routePath,
								name: route.name,
								meta: {
									...route.meta
								}
							})
						} else {
							getRoutes(route.children, routePath)
						}
					} else {
						continue
					}
				}
			}
		},
		initComponents () {
			const types = {}
			this.list = []
			Object.keys(this.labels).forEach(key => {
				this.labels[key] = 0
			})
			this.$components.forEach(el => {
				this.labels['全部']++
				if (!el.labels.length) {
					this.labels['其他']++
				}
				el.labels.forEach(label => {
					if (!this.labels[label]) {
						this.labels[label] = 0
					}
					this.labels[label]++
				})
			})
			this.$components.forEach(el => {
				let status = el.labels.includes(this.selectLabel)
				if (this.selectLabel === '全部') {
					status = true
				} else if (this.selectLabel === '其他') {
					status = !!el.labels.length
				}
				if (status) {
					if (!types[el.type]) {
						types[el.type] = []
					}
					types[el.type].push(el.id)
				}
			})
			Object.keys(types).sort().forEach(type => {
				this.list.push({
					type,
					list: types[type]
				})
			})
		},
		scrollTo (type) {
			let status = true
			this.list.forEach(el => {
				const rexp = new RegExp('^' + type)
				if (el.type.match(rexp) && status) {
					window.scrollTo(0, this.$refs[el.type][0].offsetTop)
					status = false
				}
			})
		},
		getBlack (type) {
			let status = true
			this.list.forEach(el => {
				const rexp = new RegExp('^' + type)
				if (el.type.match(rexp) && status) {
					status = false
				}
			})
			return this.active === type ? 'blue' : status ? 'black' : 'light'
		},
		onScroll () {
			let t = 0
			window.onscroll = () => {
				const time = new Date().getTime()
				if (time - t < 100) {
					return false
				}
				t = time
				let active = null
				this.list.forEach(el => {
					if (window.scrollY > this.$refs[el.type][0].offsetTop - 50) {
						active = el.type
					}
				})
				this.active = active;
			}
		},
		onSelectLabel (label) {
			this.selectLabel = label;
			this.initComponents()
		},
		togglt (item) {
			item.open = !item.open;
		},
		getKeys (obj) {
			const list = []
			Object.keys(obj).sort().forEach(key => {
				list.push({
					key,
					el: obj[key]
				})
			})
			return list;
		},
	},
	beforeDestroy () {
		window.onscroll = null;
	},
}
